<template>
  <Network ref="test" id="network"
           :nodes="network_nodes"
           :edges="network_edges"
           :options="network_options"
  />
</template>

<script>
import netdocService from '@/api-services/netdoc.service'
import {Network} from 'vue-vis-network'

export default {
  name: 'PPortNetwork',
  components: { Network },
  props: {
    p_port: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      network_nodes: [],
      network_edges: [],
      network_options: {
        nodes: {
          borderWidth: 4,
          font: {
            color: 'white',
            face: 'arial',
            align: 'center',
          }
        },
        edges: {
          color: 'lightgray'
        },
        layout: {
          hierarchical: {
            direction: 'LR',
            // sortMethod: 'directed'
          }
        }
      }
    }
  },
  async created() {
    await this.refresh_data()
  },
  watch: {
    $props() {
      this.refresh_data()
    }
  },
  methods: {
    async refresh_data() {
      this.bcd_name = this.$route.params.name
      netdocService.find_path_for_p_port(this.$store.state, this.p_port).then(r => {
        for (const p of r.data.module_list) {
          const node_color = p.fq_name.toHSL()

          const font_color = this.determine_font_color(node_color)
          this.network_nodes.push({ id: p.fq_name, label: p.fq_name.trim(), shape: 'box', color: node_color, font: {color: font_color} })
          if (p.parent_fq_slot) {
            this.network_edges.push({ from: p.fq_name, to: p.parent_fq_slot })
          }
        }

        for (const p of r.data.p_port_list) {
          const node_color = p.mdl_fq_name.toHSL()
          const font_color = this.determine_font_color(node_color)
          this.network_nodes.push({
            id: p.gpk,
            label: p.name.trim(),
            shape: 'circle',
            font: { align: 'right', color: font_color },
            color: p.mdl_fq_name.toHSL()
          })
          if (p.connected_gfk && p.gpk !== p.connected_gfk) {
            // if prevents duplicated edges
            if (!this.network_edges.some(e => e.from === p.connected_gfk && e.to === p.gpk)) {
              this.network_edges.push({ from: p.gpk, to: p.connected_gfk })
            }
          }
          if (p.mdl_fq_name) {
            this.network_edges.push({ from: p.gpk, to: p.mdl_fq_name })
          }
        }
        this.$refs.test.fit()
        // this.$refs.test.stabilize(100)
        this.$refs.test.stopSimulation()
        setTimeout(() => this.$refs.test.fit(), 20)
      })
    },
    determine_font_color(node_color) {
      const node_color_rel_luminance = this.calc_relative_luminance(this.extract_color_information(node_color))
      const white_ratio = 1.05 / (node_color_rel_luminance + 0.05)
      const black_ratio = (node_color_rel_luminance + 0.05) / 0.05
      if (black_ratio >= 10 && white_ratio > 1) {
        return 'black'
      } else {
        return 'white'
      }
    },
    extract_color_information(color_string) {
      const h = Number(color_string.substring(color_string.indexOf('(') + 1, color_string.indexOf(','))) % 360
      const s = Number(color_string.substring(color_string.indexOf(',') + 1, color_string.indexOf('%')).trim()) / 100
      const v = Number(color_string.substring(color_string.lastIndexOf(',') + 1, color_string.lastIndexOf('%')).trim()) / 100
      return [h, s, v]
    },
    calc_relative_luminance(hsl_color) {
      function hslToRgb(h, s, l) {
        const chroma = (1 - Math.abs(2 * l - 1)) * s
        const h_tmp = h / 60
        const x = chroma * (1 - Math.abs(h_tmp % 2 - 1))

        let rgb = []
        if (h_tmp > 0 && h_tmp <= 1) {
          rgb = [chroma, x, 0]
        } else if (h_tmp <= 2) {
          rgb = [x, chroma, 0]
        } else if (h_tmp <= 3) {
          rgb = [0, chroma, x]
        } else if (h_tmp <= 4) {
          rgb = [0, x, chroma]
        } else if (h_tmp <= 5) {
          rgb = [x, 0, chroma]
        } else if (h_tmp <= 6) {
          rgb = [chroma, 0, x]
        }

        const m = l - chroma / 2

        rgb[0] = rgb[0] + m
        rgb[1] = rgb[1] + m
        rgb[2] = rgb[2] + m
        return rgb
      }

      const rgb = hslToRgb(hsl_color[0], hsl_color[1], hsl_color[2])

      // see https://en.wikipedia.org/wiki/Relative_luminance
      return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]
    },
  }
}
</script>

<style scoped>
#network {
  width: 100%;
  height: 60vh;
}
</style>
