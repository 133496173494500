<template>
  <div class="ml-2 mb-3">
    <div class="path_entry" v-for="mdl_gpk in Object.keys(chunked_p_port_path)" :key="mdl_gpk">
      <div v-b-toggle="'port_collapse_' + mdl_gpk" class="path_module">
        <NetvsIcon icon="wire" v-if="cable_module_types.includes(modules[mdl_gpk].type)" />
        <NetvsIcon icon="device" v-else-if="modules[mdl_gpk].is_active" />
        <NetvsIcon icon="dd_module" v-else />
        <RouterLink :to="'/netdoc/modules/' + mdl_gpk" class="ml-1">
          {{ modules[mdl_gpk].fq_name }}
        </RouterLink>
        <span class="text-muted ml-1">
          -<span class="ml-1">({{ modules[mdl_gpk].type }})</span>
        </span>
        <NetvsIcon class="collapse-icon float-right" icon="collapse" />
        <span class="mr-xl-5 float-right text-muted">
          <NetvsIcon icon="building" v-if="modules[mdl_gpk].bldg" />
          {{ modules[mdl_gpk].bldg }}
          <NetvsIcon icon="room" v-if="modules[mdl_gpk].room" />
          {{ modules[mdl_gpk].room }}
        </span>
      </div>
      <b-collapse :visible="!cable_module_types.includes(modules[mdl_gpk].type) || chunked_p_port_path[mdl_gpk].some(p => p.gpk === p_port.gpk)" :id="'port_collapse_' + mdl_gpk">
        <ul class="ml-1">
          <li v-for="port in chunked_p_port_path[mdl_gpk]" :key="port.gpk">
            <RouterLink :to="'/netdoc/modules/' + port.module_gfk + '/p_port/' + port.gpk">
              {{ port.mdl_fq_name }}::{{ port.name }}
            </RouterLink>
            <b-badge variant="danger" v-if="port.gpk === p_port.gpk"><NetvsIcon icon="geo_marker" /> {{ $t('views.nd.you_are_here') }}</b-badge>
          </li>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import netdocService from '@/api-services/netdoc.service'
import apiutil from '@/util/apiutil'
import NetvsIcon from '@/icons/NETVSIcon.vue'

export default {
  name: 'PPortPath',
  components: { NetvsIcon },
  props: {
    p_port: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modules: {},
      module_types: {},
      p_ports: {},
      p_port_path: [],
      chunked_p_port_path: {},
    }
  },
  async created() {
    await this.refresh_data()
  },
  watch: {
    $props() {
      this.refresh_data()
    }
  },
  computed: {
    cable_module_types() {
      return Object.values(this.module_types).filter(m => m.class.startsWith('StatCbl') || m.class.startsWith('PatchCbl')).map(m => m.name)
    }
  },
  methods: {
    async refresh_data() {
      this.bcd_name = this.$route.params.name
      netdocService.find_path_for_p_port(this.$store.state, this.p_port).then(r => {
        this.modules = apiutil.dict_by_value_of_array(r.data.module_list, 'gpk')
        this.module_types = apiutil.dict_by_value_of_array(r.data.module_type_list, 'name')
        this.p_ports = apiutil.dict_by_value_of_array(r.data.p_port_list, 'gpk')

        let p_port = this.p_ports[this.p_port.connection_id_gfk_list.slice(-1)[0]]
        let internal = p_port.gpk === p_port.internal_connected_gfk
        this.p_port_path = []
        while (p_port && !this.p_port_path.includes(p_port)) {
          this.p_port_path.push(p_port)
          internal = !internal
          if (internal) {
            p_port = this.p_ports[p_port.internal_connected_gfk]
          } else {
            p_port = this.p_ports[p_port.connected_gfk]
          }
        }
        this.chunked_p_port_path = apiutil.dict_of_lists_by_value_of_array(this.p_port_path, 'module_gfk')
      })
    }
  }
}
</script>

<style scoped>
.path_module {
  background: #e1e1e1;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
}
.path_entry {
  background: #f5f5f5;
}
.path_entry ul {
  margin-bottom: 0;
}
</style>
